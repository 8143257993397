import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';

import * as s from './map-section.module.scss';
import * as s_form from '@assets/styles/form.module.scss';
import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';
import * as s_btns from '@assets/styles/btns.module.scss';

const google_maps_key = 'AIzaSyDnh12TmMyHNoRwNg8yJFe08iyD_drz8QE';


const MapSection = () => {

    const mapRef = useRef(null);


    useEffect(() => {

        if(window.google === undefined || window.google.maps === undefined){
            if(window.location.pathname !== '/'){
                let google_api = document.createElement("script");
                google_api.src = `https://maps.googleapis.com/maps/api/js?key=${google_maps_key}`;
                google_api.id = "google-map-api";
                google_api.async = true;
                google_api.defer = true;
                google_api.addEventListener('load', () => {
                    initMap();
                });

                document.body.appendChild(google_api);
            } else {
                let count = 0;
                let interval = setInterval(() => {
                    if(window?.google?.maps !== undefined){
                        initMap();
                        clearInterval(interval);
                    }
                    if(count > 100){
                        clearInterval(interval);
                    }
                    count++;
                }, 200);
            }
        } else {
            initMap();
        }

    }, []);


    const initMap = () => {
        //map
        const loc = { lat: 50.2657675, lng: 18.9938128 };
        const map = new window.google.maps.Map(mapRef.current, {
            mapId: 'b5cdd8a02cc99c70',
            zoom: 14,
            center: { lat: 50.263592, lng: 19.031390 },
            disableDefaultUI: true
        });
        const marker = new window.google.maps.Marker({
            position: loc,
            map: map,
            icon: 'https://click-leasing.pl/map_pin.png',
            // label: {
            //     text: "Clicklease - ul. Kozielska 5",
            //     color: "#171717",
            //     fontWeight: "bold"
            // }
            // icon: {
            //     url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
            //     labelOrigin: new google.maps.Point(75, 32),
            //     size: new google.maps.Size(32,32),
            //     anchor: new google.maps.Point(16,32)
            //   },

        });
    }


    const [reqStatus, setReqStatus] = useState({isSuccess: false, isLoading: false, isError: false, errorMessage: ''})
	const { register, handleSubmit, watch, formState: { errors, isValid } } = useForm({mode: 'onChange'});

	const submitHandler = async (data) => {
		if(!reqStatus.isSuccess){
            try{
                setReqStatus({isSuccess: false, isLoading: true, isError: false, errorMessage: ''});

                let formData = new FormData();
                formData.append('name-356', data.name);
                formData.append('tel-356', data.tel);
                formData.append('email-545', data.email);
                formData.append('message-356', data.message);


                const res = await axios.post(`https://click-leasing.pl/panel/wp-json/contact-form-7/v1/contact-forms/3694/feedback`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }
                );

                setReqStatus({isSuccess: true, isLoading: false, isError: false, errorMessage: ''});

            } catch (error) {
                setReqStatus({isSuccess: false, isLoading: false, isError: true, errorMessage: 'Wystąpił błąd.'});
            }
        }
	}


    return (
        <>

            <section className={`${s.map_section}`}>

                <div className={s.map} ref={mapRef}></div>

                <div className={s.form_wrapper}>

                    <h2>Formularz</h2>

                    <form onSubmit={handleSubmit(submitHandler)} className={`${s_form.apply}`}>

                        <div className={s_form.request_response}>
                            <span className={s_form.error}>
                                {reqStatus.isError && reqStatus.errorMessage}
                            </span>
                            <span className={s_form.success}>
                                {reqStatus.isSuccess && 'Wysłano!'}
                            </span>
                        </div>



                        {/* name */}
                        <div className={s_form.field_wrapper}>
                            <span className={s_form.error_text}>
                                {errors.name?.type === 'required' && 'Wpisz imię i nazwisko.'}
                            </span>
                            <input id='form_map_name' className={errors.name ? s_form.error : ''} placeholder="&nbsp;" type="text"
                                {...register("name",
                                    {
                                        required: true
                                    }
                                )}
                            />
                            <label htmlFor='form_map_name'>Imię i nazwisko</label>
                        </div>


                        {/* tel */}
                        <div className={s_form.field_wrapper}>
                            <span className={s_form.error_text}>
                                {errors.tel?.type === 'required' && 'Wpisz numer telefonu.'}
                                {errors.tel?.type === 'pattern' && 'Niepoprawny telefon'}
                            </span>
                            <input id='form_map_tel' className={errors.tel ? s_form.error : ''} placeholder="&nbsp;" type="tel"
                                onKeyPress={(event) => {
                                    if (!/[0-9 +-]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                {...register("tel",
                                    {
                                        required: true,
                                        pattern: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
                                    }
                                )}
                            />
                            <label htmlFor='form_map_tel'>Numer telefonu</label>
                        </div>


                        {/* email */}
                        <div className={s_form.field_wrapper}>
                            <span className={s_form.error_text}>
                                {errors.email?.type === 'required' && 'Wpisz adres e-mail.'}
                            </span>
                            <input id='form_map_email' className={errors.email ? s_form.error : ''} placeholder="&nbsp;" type="email"
                                {...register("email",
                                    {
                                        required: true
                                    }
                                )}
                            />
                            <label htmlFor='form_map_email'>E-mail</label>
                        </div>


                        {/* message */}
                        <div className={s_form.field_wrapper}>
                            <span className={s_form.error_text}>
                                {errors.message?.type === 'required' && 'Wpisz wiadomość.'}
                            </span>
                            <div className={s_form.textarea_wrapper}>
                                <textarea id='form_map_message' className={errors.message ? s_form.error : ''} placeholder="&nbsp;"
                                    {...register("message",
                                        {
                                            required: true
                                        }
                                    )}
                                />
                                <label htmlFor='form_map_message'>Treść wiadomości</label>
                            </div>
                        </div>


                        {/* rules */}
                        <div className={s_form.field_wrapper}>
                            <span className={s_form.error_text}>
                                {errors.rules && 'Zaznacz wymagane zgody.'}
                            </span>
                            <div className={s_form.checkbox_wrapper}>
                                <input className={errors.rules ? s_form.error : ''} type="checkbox"
                                    id='form_map_rules'
                                    {...register("rules",
                                        {
                                            required: true
                                        }
                                    )}
                                />
                                <label className={s_form.checkbox_label} htmlFor='form_map_rules'>Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z ustawą o ochronie danych osobowych w związku z realizacją mojego zapytania, oraz o kontakt drogą telefoniczną lub elektroniczną. <a href='/polityka-prywatnosci.pdf' target='_blank'>Zobacz&nbsp;Politykę&nbsp;prywatności</a> *</label>
                            </div>
                        </div>



                        {/* //loading spinner or effect on btn */}
                        <button type="submit" className={`${!isValid || reqStatus.isSuccess || reqStatus.isLoading ? s_form.inactive : ''} ${s_btns.btn_full}`} >Wyślij</button>
                    </form>

                </div>

            </section>
        </>
    )
};

export default MapSection;
