import React from 'react';


import * as s from './about-section.module.scss';
import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';


const AboutSection = () => {



    return (
        <section className={`${s.about} ${s_wrapper.apply} ${s_wrapper.apply_low_top_height}`}>

            <h2>O ClickLease</h2>
            <p className={`${s_text.subtitle} ${s.subtitle}`}>
	            Specjalizujemy się w pozyskiwaniu dla naszych klientów finansowania poprzez leasing, bo wiemy, że to jest korzystne rozwiązanie.
            </p>

            <article>
            <div>
                <p>
	                Zajmujemy się również kredytami samochodowymi i gotówkowymi oraz pożyczkami. W zakresie naszej działalności znajduje się również faktoring.
                </p>

                <p>
	                Od 28 lat prowadzę własną firmę.
	                Od początku działalności do dnia dzisiejszego, zajmuję się kontaktami bezpośrednimi i obsługą klientów indywidualnych oraz biznesowych.
	                Zawsze za cel główny mam zadowolenie moich klientów poprzez dostarczanie produktu najwyższej jakości oraz zapewnienie obsługi na najwyższym poziomie.
                </p>

	            <p>
		            Wieloletnie doświadczenie w sprzedaży i obsłudze klienta pozwala mi stwierdzić i zagwarantować, iż dobro i interesy Klienta zawsze w naszej pracy będą się znajdowały na pierwszym miejscu.
	            </p>

            </div>
            <div>
                <h3>Co zyskasz poprzez leasing z nami</h3>
	            <p>
		            Moim Klientom gwarantuję:
	            </p>
                <ul className={`${s_text.checkmark_list}`}>
	                <li>Profesjonalne, rzetelne i skuteczne działanie w imieniu i na rzecz klienta,</li>
	                <li>Oszczędność czasu klienta poprzez kompleksowo świadczone usługi oraz załatwianie wszystkich formalności od A do Z,
	                </li>
	                <li>Najkorzystniejsze i najniżej oprocentowane oferty leasingowe i kredytowe dostępne na rynku,
	                </li>
	                <li>Obsługę klienta poprzez stały kontakt, ale też wsparcie i pomoc na każdym etapie procesu,
	                </li>
	                <li>Bezpieczeństwo w kontaktach z nami – poprzez zachowanie pełnej dyskrecji i poufności informacji, przestrzeganie tajemnicy bankowej oraz ochrony danych osobowych,
	                </li>
	                <li>
		                Satysfakcję i zadowolenie z doboru rozwiązań odpowiadających oczekiwaniom klienta.
	                </li>
                </ul>
                <p>
	                <b>
		                Czuję się zaszczycony i jestem dumny z zaufania jakim darzą nas nasi klienci, dlatego niezmiennie
		                na pierwszym miejscu stawiamy interesy i dobro Klienta oraz przykładamy najwyższą uwagę
		                do działania zgodnego z etyką zawodu brokera finansowego.
	                </b>
                </p>
            </div>
            </article>

        </section>
    )
};

export default AboutSection;
